import React, { useState } from 'react';
import styles from './Placements.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const Placements = () => {
  // Sample data - replace with actual data
  const placementData = [
    {
      sNo: 1,
      rollNo: "2024001",
      name: "John Doe",
      trade: "Electrician",
      passingYear: "2024",
      organisation: "Tech Corp Ltd",
      salary: "25000"
    },
    {
      sNo: 2,
      rollNo: "2024002",
      name: "Jane Smith",
      trade: "Fire Technology",
      passingYear: "2024",
      organisation: "Safety Systems Inc",
      salary: "28000"
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <h1 className={styles.title}>Placements</h1>
      
      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Roll No.</th>
                <th>Name</th>
                <th>Trade</th>
                <th>Passing year</th>
                <th>Organisation Name</th>
                <th>Salary on Joining</th>
              </tr>
            </thead>
            <tbody>
              {placementData.map((placement) => (
                <tr key={placement.sNo}>
                  <td>{placement.sNo}</td>
                  <td>{placement.rollNo}</td>
                  <td>{placement.name}</td>
                  <td>{placement.trade}</td>
                  <td>{placement.passingYear}</td>
                  <td>{placement.organisation}</td>
                  <td>₹{placement.salary}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Placements;