import React from 'react'
import Sidebar from '../../Components/Sidebar/Sidebar'

const Dashborad = () => {
  return (
    <div>
      <Sidebar/>
    </div>
  )
}

export default Dashborad
