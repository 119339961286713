import React from "react";
import styles from "./SliderManager.module.scss";
import { FaTrash } from "react-icons/fa";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const SliderManager = () => {
  return (
    <div className={styles.main}>
    <Sidebar data={"slidermanager"}/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Slider Panel</h2>
        <div className={styles.controls}>
          <select className={styles.select}>
            <option>10</option>
            <option>20</option>
          </select>
          <span>records per page</span>
          <input type="text" placeholder="Search" className={styles.search} />
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2].map((item) => (
            <tr key={item}>
              <td>{item}</td>
              <td>
                <img src="/placeholder.jpg" alt="Slider" className={styles.image} />
              </td>
              <td>
                <button className={styles.deleteButton}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button className={styles.pageButton}>← Previous</button>
        <span>1</span>
        <button className={styles.pageButton}>Next →</button>
      </div>
      <div className={styles.form}>
        <input type="text" placeholder="Image Title" className={styles.input} />
        <input type="file" className={styles.fileInput} />
        <button className={styles.saveButton}>Save Changes</button>
      </div>
    </div>
    </div>
  );
};

export default SliderManager;