import React, { useState } from 'react';
import styles from './Sidebar.module.scss';

const Sidebar = ({data}) => {
  const [activeItem, setActiveItem] = useState(data?data:'dashboard');
  const menuItems = [
    { icon: 'home', label: 'Main Dashboard', href:"dashboard"  },
    { icon: 'menu', label: 'Menus Manager', href:"managemanagement" },
    { icon: 'page', label: 'Pages Manager', href:"pagemanagement" },
    { icon: 'gear', label: 'Option Manager', href:"optionmanager" },
    { icon: 'slider', label: 'Slider Manager', href:"slidermanager" },
    { icon: 'gallery', label: 'Gallery Manager', href:"photopanel" },
    { icon: 'file', label: 'Documents Manager', href:"documentpanel" },
    { icon: 'announce', label: 'Announcement Manager', href:"announcement" },
  ];

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebar}>
        <div className={styles.headerSection}>
          <h3 className={styles.sectionTitle}>MAIN</h3>
        </div>
        
        <ul className={styles.menuList}>
          {menuItems.map((item) => (
            <li 
              key={item.label} 
              className={`${styles.menuItem} ${activeItem === item.href ? styles.active : ''}`}
              onClick={() => setActiveItem(item.href)}
            >
              <a href={item.href} className={styles.menuLink}>
                <span className={`${styles.icon} ${styles[item.icon]}`}></span>
                <span className={styles.menuText}>{item.label}</span>
              </a>
            </li>
          ))}
        </ul>
        
        <div className={styles.optionSection}>
          <label className={styles.toggle}>
            <input type="checkbox" className={styles.toggleInput} />
            <span className={styles.toggleSlider}></span>
            <span className={styles.toggleLabel}>Ajax on menu</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;