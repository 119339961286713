import React, { useState } from 'react';
import styles from './CourtCaseStatus.module.scss';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';



const CourtCaseStatus = () => {
  const [tableData,setTableData] = useState([
    {
      caseNo: "N/A",
      year: "N/A",
      issue: "N/A",
      status: "N/A"
    }
  ])

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>Court Case & Status</h2>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>CWP No./ Name Of Court</th>
                <th>Year</th>
                <th>Issue</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.caseNo}</td>
                  <td>{row.year}</td>
                  <td>{row.issue}</td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
   < Footer/>
    </div>
  );
};

export default CourtCaseStatus;