import React from 'react';
import styles from './AdministrativeStaff.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const AdministrativeStaff = () => {
  const staffData = [
    {
      id: 1,
      name: 'VIJENDRA SINGH',
      designation: 'COMPUTER TEACHER',
      dateOfJoining: 'Feb-25',
      status: 'OK'
    },
    {
      id: 2,
      name: 'RAKESH KUMAR',
      designation: 'ADMINISTRATIVE',
      dateOfJoining: '8-Oct-18',
      status: 'OK'
    },
    {
      id: 3,
      name: 'PRAKASH SAINI',
      designation: 'GATE MAN',
      dateOfJoining: 'JUL-2022',
      status: 'OK'
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Administrative Staff</h2>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>S.No.</th>
              <th className={styles.th}>Name of Staff</th>
              <th className={styles.th}>Designation</th>
              <th className={styles.th}>Date of Joining</th>
              <th className={styles.th}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {staffData.map((staff) => (
              <tr key={staff.id} className={styles.tr}>
                <td className={styles.td}>{staff.id}</td>
                <td className={styles.td}>{staff.name}</td>
                <td className={styles.td}>{staff.designation}</td>
                <td className={styles.td}>{staff.dateOfJoining}</td>
                <td className={styles.td}>
                  <span className={styles.status}>{staff.status}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default AdministrativeStaff;