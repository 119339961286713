import React from 'react';
import styles from './Library.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const Library = () => {
  const books = [
    { name: 'RAJATHAN PATRIKA', trade: '', quantity: '1/Day', author: '', publisher: '' },
    { name: 'DENIK BHASKAR', trade: '', quantity: '1/Day', author: '', publisher: '' },
    { name: 'COPA', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'IT TOOLS AND APPLICATION', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'BUSINESS SYSTEM', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'Electrical Theory', trade: 'Electrician', quantity: '10', author: 'NIMI', publisher: 'Arihant' },
    { name: 'Fire Technology and Industrial Safety Management Theory', trade: 'Fire Technology & Industrial Safety Management', quantity: '50', author: 'Jitendra Sharma', publisher: 'Arihant' },
    { name: 'Employment Skill', trade: 'Electrician,FT&ISM', quantity: '50', author: 'Jitendra Sharma', publisher: 'Arihant' },
    { name: 'Foxpro', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'Operating System', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'Programming in C', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'System Analysis & design', trade: '', quantity: '', author: '', publisher: '' },
    { name: 'Visual Basic Dot Net', trade: '', quantity: '', author: '', publisher: '' }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Library</h2>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>Name of Book</th>
              <th className={styles.th}>Trade</th>
              <th className={styles.th}>Quantity</th>
              <th className={styles.th}>Author Name</th>
              <th className={styles.th}>Publisher Name</th>
            </tr>
          </thead>
          <tbody>
            {books.map((book, index) => (
              <tr key={index} className={styles.tr}>
                <td className={styles.td}>{book.name}</td>
                <td className={styles.td}>{book.trade}</td>
                <td className={styles.td}>
                  {book.quantity && (
                    <span className={styles.quantity}>{book.quantity}</span>
                  )}
                </td>
                <td className={styles.td}>{book.author}</td>
                <td className={styles.td}>{book.publisher}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Library;