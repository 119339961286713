import logo from './logo.svg';
import './App.css';
import Routers from './Routes/Route';

function App() {
  return (
    <div >
     <Routers/>
    </div>
  );
}

export default App;
