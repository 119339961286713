import React from 'react';
import styles from './IndustryInstitute.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const IndustryLinkages = () => {
  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Industry Institute Linkages</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>
                  Name of the Industry Partner/MoU
                </th>
                <th className={styles.headerCell}>
                  Major Activities/Contributions
                </th>
                <th className={styles.headerCell}>
                  Industry Visit/Industrial Tour
                </th>
                <th className={styles.headerCell}>
                  Guest Faculty
                </th>
                <th className={styles.headerCell}>
                  Workshops/Seminars
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default IndustryLinkages;