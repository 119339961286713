import React from 'react';
import styles from './AdmissionCriteria.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import Slider from '../../../Components/Slider/Slider';

const AdmissionCriteria = () => {
  const tradeData = [
    {
      sNo: 1,
      tradeName: "FIRE TECHNOLOGY AND SEFITY MANAGEMENT",
      duration: "01 Year",
      eligibility: "10 th"
    },
    {
      sNo: 2,
      tradeName: "Electrician",
      duration: "02 Years",
      eligibility: "10th"
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <h1 className={styles.title}>Admission Criteria</h1>
      
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name of Trade under (NCVT)</th>
              <th>Duration of Training</th>
              <th>Eligibility Qualification</th>
            </tr>
          </thead>
          <tbody>
            {tradeData.map((trade) => (
              <tr key={trade.sNo}>
                <td>{trade.sNo}</td>
                <td>{trade.tradeName}</td>
                <td>{trade.duration}</td>
                <td>{trade.eligibility}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default AdmissionCriteria;
