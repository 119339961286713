import React from 'react';
import styles from './ManagingTrust.module.scss';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';

const ManagingTrust = () => {
  const trustInfo = {
    name: 'Virendra PVT ITI Bagri',
    registrationNumber: '',
    // validityDate: '07 2016-17',
    remark: 'ok'
  };

  const members = [
   {
    id:1,
    name:"Shishupal",
    designation:"PRESIEDENT"
   },
   {
    id:2,
    name:"Virendra Thalor",
    designation:"DIRECTOR"
   },
   {
    id:3,
    name:"Mahendra Kumar",
    designation:"SECURATRY"
   }
  ];

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <section className={styles.trustSection}>
        <h1 className={styles.mainTitle}>Managing Trust</h1>
        <div className={styles.trustInfo}>
          <div className={styles.infoRow}>
            <div className={styles.label}>Name of the Trust</div>
            <div className={styles.value}>{trustInfo.name}</div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.label}>Registration Number</div>
            <div className={styles.value}>{trustInfo.registrationNumber}</div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.label}>Validity Date</div>
            <div className={styles.value}>{trustInfo.validityDate}</div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.label}>Scanned Copy Of certificate</div>
            <div className={styles.value}></div>
          </div>
          <div className={styles.infoRow}>
            <div className={styles.label}>Remark</div>
            <div className={styles.value}>{trustInfo.remark}</div>
          </div>
        </div>
      </section>

      <section className={styles.membersSection}>
        <h2 className={styles.sectionTitle}>Name's of Chairman & Members</h2>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>S No.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Father's Name</th>
                <th>Address</th>
                <th>Mobile No.</th>
                <th>UID No.</th>
                <th>Whether member of any other Society/Trust/Company</th>
                <th>If answer of column 8 is yes, please furnish details</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => (
                <tr key={member.id}>
                  <td>{member.id}</td>
                  <td>{member.name}</td>
                  <td>{member.designation}</td>
                  <td>{member.fatherName || '-'}</td>
                  <td>{member.address}</td>
                  <td>{member.mobileNo}</td>
                  <td>{member.uidNo || '-'}</td>
                  <td>{member.otherMembership || '-'}</td>
                  <td>{member.details || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default ManagingTrust;