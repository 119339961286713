import React from 'react';
import styles from './Sports.module.scss';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';

const Sports = () => {
  const sportsData = [
    {
      name: 'Football',
      equipment: 'Ball (2), Wistle, Net(2) Flag',
      detail: 'Every Day Play'
    },
    {
      name: 'Volley Ball',
      equipment: 'Ball, net whistle',
      detail: 'Every Day Play'
    },
    {
      name: 'Charame Board',
      equipment: 'Standard size board coins striker',
      detail: 'Every Day Play'
    },
    {
      name: 'Table Tenis',
      equipment: 'One Table ball net bat (4)',
      detail: 'Every Day Play'
    },
    {
      name: 'Cricket',
      equipment: 'Cricket bat (2), ball (2), stumps (2), leg pads(2), gloves(2)',
      detail: 'Every Day Play'
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Sports</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>Name</th>
                <th className={styles.headerCell}>Equipment</th>
                <th className={styles.headerCell}>Detail</th>
              </tr>
            </thead>
            <tbody>
              {sportsData.map((sport, index) => (
                <tr 
                  key={sport.name}
                  className={`${styles.tableRow} ${index % 2 === 0 ? styles.evenRow : ''}`}
                >
                  <td className={styles.tableCell}>{sport.name}</td>
                  <td className={styles.tableCell}>{sport.equipment}</td>
                  <td className={styles.tableCell}>{sport.detail}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Sports;