import React, { useState } from 'react';
import styles from './Login.module.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  
  // Generate random numbers for captcha
  const num1 = Math.floor(Math.random() * 10);
  const num2 = Math.floor(Math.random() * 10);
  const captchaResult = num1 + num2;
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate captcha
    if (parseInt(captcha) !== captchaResult) {
      alert('Incorrect captcha solution');
      return;
    }
    
    // Handle login logic here
    console.log('Login attempt with:', { username, password });
  };
  
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginCard}>
        <div className={styles.logoContainer}>
          <img 
            src="/logo.png" 
            alt="Marwal Infotech" 
            className={styles.logo} 
          />
        </div>
        
        <div className={styles.loginHeader}>
          Enter Username and Password
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <span className={styles.inputIcon}>👤</span>
            <input
              type="text"
              placeholder="User Name"
              className={styles.inputField}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          
          <div className={styles.inputGroup}>
            <span className={styles.inputIcon}>🔒</span>
            <input
              type="password"
              placeholder="Password"
              className={styles.inputField}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          
          <div className={styles.captchaGroup}>
            <div className={styles.captchaQuestion}>
              {num1} + {num2} = ?
            </div>
            <input
              type="text"
              placeholder="Solution"
              className={styles.captchaInput}
              value={captcha}
              onChange={(e) => setCaptcha(e.target.value)}
              required
            />
          </div>
          
          <button type="submit" className={styles.loginButton}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;