// TradesTables.jsx
import React from 'react';
import styles from './Trades.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const Trades = () => {
  const ncvtScvtTrades = [
    {
      tradeName: "ELECTRICIAN",
      units: 6,
      shift1: 2,
      shift2: 2,
      shift3: 2,
      seatingPerUnit: 20,
      totalSeating: 120,
      affiliationDate: "15/12/2016",
      dgetOrder: "DGT-12/1/2017-TC"
    },
    {
      tradeName: "FIRE AND INDUSTRIAL SEFITY MANAGEMENT",
      units: 3,
      shift1: 1,
      shift2: 1,
      shift3: 1,
      seatingPerUnit: 24,
      totalSeating: 72,
      affiliationDate: "15/12/2016",
      dgetOrder: "DGT-12/1/2017-TC"
    }
  ];

  const ncvtTrades = [
    {
      tradeName: "FIRE TECHNOLOGY AND SEFITY MANAGEMENT",
      shiftIntake1: 24,
      shiftIntake2: 24,
      shiftIntake3: 24,
      totalUnits: 3,
      dgetOrder: "DGET-"
    },
    {
      tradeName: "ELECTRICIAN",
      shiftIntake1: 40,
      shiftIntake2: 40,
      shiftIntake3: 40,
      totalUnits: 6
    }
  ];

  const scvtTrades = [
    {
      tradeName: "-",
      shiftIntake1: "-",
      shiftIntake2: "-",
      shiftIntake3: "-",
      totalUnits: 0,
      remark: "-"
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.card}>
          <h2 className={styles.sectionTitle}>Trades Affiliated to NCVT & SCVT</h2>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Name of the Trade</th>
                  <th>No. of Units</th>
                  <th>Shifts Running 1</th>
                  <th>Shifts Running 2</th>
                  <th>Shifts Running 3</th>
                  <th>Seating Capacity per Unit</th>
                  <th>Total Seating Capacity</th>
                  <th>Affiliation Date</th>
                  <th>DGET Order</th>
                </tr>
              </thead>
              <tbody>
                {ncvtScvtTrades.map((trade, index) => (
                  <tr key={index}>
                    <td className={styles.tradeName}>{trade.tradeName}</td>
                    <td>{trade.units}</td>
                    <td>{trade.shift1}</td>
                    <td>{trade.shift2}</td>
                    <td>{trade.shift3}</td>
                    <td>{trade.seatingPerUnit}</td>
                    <td className={styles.highlight}>{trade.totalSeating}</td>
                    <td>{trade.affiliationDate}</td>
                    <td>{trade.dgetOrder}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.card}>
          <h2 className={styles.sectionTitle}>Summary of Trades Affiliated to NCVT</h2>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Trade Name</th>
                  <th>Shift Intake capacity 1</th>
                  <th>Shift Intake capacity 2</th>
                  <th>Shift Intake capacity 3</th>
                  <th>Total No. of Units</th>
                  <th>DGET order no.</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {ncvtTrades.map((trade, index) => (
                  <tr key={index}>
                    <td className={styles.tradeName}>{trade.tradeName}</td>
                    <td>{trade.shiftIntake1}</td>
                    <td>{trade.shiftIntake2}</td>
                    <td>{trade.shiftIntake3}</td>
                    <td className={styles.highlight}>{trade.totalUnits}</td>
                    <td>{trade.dgetOrder}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.card}>
          <h2 className={styles.sectionTitle}>Summary of Trades Affiliated to SCVT</h2>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Trade Name</th>
                  <th>Shift Intake capacity 1</th>
                  <th>Shift Intake capacity 2</th>
                  <th>Shift Intake capacity 3</th>
                  <th>Total No. of Units</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {scvtTrades.map((trade, index) => (
                  <tr key={index}>
                    <td>{trade.tradeName}</td>
                    <td>{trade.shiftIntake1}</td>
                    <td>{trade.shiftIntake2}</td>
                    <td>{trade.shiftIntake3}</td>
                    <td className={styles.highlight}>{trade.totalUnits}</td>
                    <td>{trade.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Trades;