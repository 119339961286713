import React from 'react';
import styles from './DGET.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const DGET = () => {
  return (<div>
  <Header/>
  <Slider/>
<div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>DGET Order</h1>
        
        <div className={styles.sectionHeader}>
          DGET and State Govt orders issued from time to time
        </div>
        
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>Order No. & Date</th>
                <th className={styles.headerCell}>Subject of the order</th>
                <th className={styles.headerCell}>Link scanned copy</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
    
  );
};

export default DGET;