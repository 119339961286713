import React from "react";
import styles from "./Iso.module.scss";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import Slider from "../../../Components/Slider/Slider";

const Iso = () => {
  const ratings = [
    {
      id: 1,
      agency: "ICRA MANAGEMENT CONSULTING SERVICES LIMITED [IMaCS]",
      rating: "Details",
    },
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <h2 className={styles.title}>ISO</h2>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>S No.</th>
              <th>Title</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {ratings.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.agency}</td>
                <td>{item.rating}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Iso;
