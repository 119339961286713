import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import ContactForm from "../Pages/Contact/Contact";
import Infrastructure from "../Pages/About/Infrastructure/Infrastructure";
import ManagingTrust from "../Pages/About/ManagingTrust/ManagingTrust";
import VisionMission from "../Pages/About/Vission&Mission/Vission&Mission";
import StateDirectorate from "../Pages/About/StateDirectorate/StateDirectorate";
import InstituteRating from "../Pages/About/InstituteRating/InstituteRating";
import Iso from "../Pages/About/ISO/Iso";
import CourtCaseStatus from "../Pages/About/CourtCaseStatus/CourtCaseStatus";
import AttendenceRecords from "../Pages/Students/AttendenceRecords/AttendenceRecord";
import Certification from "../Pages/Students/Certification/Certification";
import AdmissionCriteria from "../Pages/Students/AdmissionCriteria/AdmissionCriteria ";
import ApplicationFormat from "../Pages/Students/ApplicationFormat/ApplicationFormat";
import Placements from "../Pages/Students/Placements/Placements";
import Results from "../Pages/Students/Results/Results";
import AchievementsTable from "../Pages/Students/AchiveTrainees/AchiveTrainees";
import Schemes from "../Pages/Trades/Schemes/Schemes";
import Trades from "../Pages/Trades/Trades/Trades";
import TechnicalStaff from "../Pages/Staff/TechnicalStaff/TechnicalStaff";
import AdministrativeStaff from "../Pages/Staff/AdministrativeStaff/AdministrativeStaff";
import StaffAttendance from "../Pages/Staff/StaffAttendance/StaffAttendance";
import InstructorAwards from "../Pages/Staff/InstructorAwards/InstructorAwards";
import PowerSupplyDetails from "../Pages/Facilities/ElectricPower/ElectricPower";
import Library from "../Pages/Facilities/Library/Library";
import ComputerLab from "../Pages/Facilities/ComputerLab/ComputerLab";
import Sports from "../Pages/Facilities/Sports/Sports";
import IndustryLinkages from "../Pages/Facilities/IndustryInstitute/IndustryInstitute";
import IndustryVisit from "../Pages/Facilities/IndustryVisit/IndustryVisit";
import ExtraCurricular from "../Pages/Facilities/ExtraCurricular/ExtraCurricular";
import RightToInformation from "../Pages/More/RTI/RTI";
import Grievance from "../Pages/More/Grievance/Grievance";
import FundsDetails from "../Pages/More/FundsDetails/FundsDetails";
import DGET from "../Pages/More/DGETorder/DGET";
import RawMaterialConsumption from "../Pages/Consumption/RawMaterial/RawMaterialConsumption";
import ElectricalDashboard from "../Pages/Consumption/ElectricalConsumption/ElectricalConsumption";
import Gallery from "../Pages/Gallery/Gallery";
import MaintenanceExpenditure from "../Pages/Consumption/MaintenanceExpenditure/MaintenanceExpenditure";
import Dashborad from "../Pages/Admin/Dashborad";
import Login from "../Pages/Admin/Login/Login";
import Student from "../Pages/Admin/Student/Student";
import StudentDetails from "../Pages/Admin/StudentDetails/StudentDetails";
import MenuManagement from "../Pages/Admin/MenuManger/MenuManager";
import PagesManagement from "../Pages/Admin/PagesManagement/PagesManagement";
import OptionManager from "../Pages/Admin/OptionManager/OptionManager";
import SliderManager from "../Pages/Admin/SliderManager/SliderManager";
import PhotoPanel from "../Pages/Admin/PhotoPanel/PhotoPanel";
import DocumentPanel from "../Pages/Admin/DocumentPanel/DocumentPanel";
import Announcement from "../Pages/Admin/Announcement/Announcement";


const Routers = () => {
  return (
    <Router>
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactForm />} />
        {/* About */}
        <Route path="/about/Infrastructure" element={<Infrastructure/>}/>
        <Route path="/about/managing-trust" element={<ManagingTrust/>} />
        <Route path="/about/vision-mission" element={<VisionMission/>}/>
        <Route path="/about/state-directorate" element={<StateDirectorate/>} />
        <Route path="/about/institute-rating" element={<InstituteRating/>}/>
        <Route path="/about/ISO" element={<Iso/>}/>
        <Route path="/about/court-case" element={<CourtCaseStatus/>}/>
        {/* Students */}
        <Route path="/students/attendance-record" element={<AttendenceRecords/>}/>
        <Route path="/students/progress-card" element={<AttendenceRecords/>}/>
        <Route path="/students/certification" element={<Certification/>}/>
        <Route path="/students/trainee-record" element={<AttendenceRecords/>}/>
        <Route path="/students/admission-criteria" element={<AdmissionCriteria/>}/>
        <Route path="/students/application-format" element={<ApplicationFormat/>} />
        <Route path="/students/placements" element={<Placements/>}/>
        <Route path="/students/results" element={<Results/>} />
        <Route path="/students/achievements-trainee" element={<AchievementsTable/>} />
        {/* Schemes */}
        <Route path="/trades/schemes" element={<Schemes/>}/>
        <Route path="/trades/trades" element={<Trades/>} />
        {/* Staff */}
        <Route path="/staff/technical-staff" element={<TechnicalStaff/>} />
        <Route path="/staff/administrative-staff" element={<AdministrativeStaff/>} />
        <Route path="/staff/staff-attendance" element={<StaffAttendance/>} />
        <Route path="/staff/awards-instructor" element={<InstructorAwards/>}/>
        {/* facilitiy */}
        <Route path="/facilities/electric-supply" element={<PowerSupplyDetails/>}/>
        <Route path="/facilities/library" element={<Library/>}/>
        <Route path="/facilities/computer-lab" element={<ComputerLab/>}/>
        <Route path="/facilities/sports" element={<Sports/>}/>
        <Route path="/facilities/industry-linkages" element={<IndustryLinkages/>} />
        <Route path="/facilities/industry-visit" element={<IndustryVisit/>}/>
        <Route path="/facilities/extra-activities" element={<ExtraCurricular/>} />
        {/* MORE */}
        <Route path="/more/RTI" element={<RightToInformation/>} />
        <Route path="/more/funds-details" element={<FundsDetails/>}/>
        <Route path="/more/DGET" element={<DGET/>}/>
        <Route path="/more/Grie" element={<Grievance/>} />
         {/* Consumption */}
         <Route path="/Consumption/raw-consumption" element={<RawMaterialConsumption/>}/>
         <Route path="/Consumption/electrical-consumption" element={<ElectricalDashboard/>}/>
          <Route path="/Consumption/maintenance-expenditure" element={<MaintenanceExpenditure/>}/>
          {/* Gallery */}
          <Route path="/gallery" element={<Gallery/>}/>
          {/* Admin Dashboard */}
          <Route path ="/admin/login" element={<Login/>}/>
          <Route path ="/admin/dashboard" element={<Dashborad/>}/>
          <Route path="/admin/student" element={<Student/>} />
          <Route path="/admin/student/detail" element={<StudentDetails/>}/>
          <Route path="/admin/managemanagement" element={<MenuManagement/>} />
          <Route path="/admin/pagemanagement" element={<PagesManagement/>} />
          <Route path="/admin/optionmanager" element={<OptionManager/>} />
          <Route path="/admin/slidermanager" element={<SliderManager/>} />
          <Route path="/admin/photopanel" element={<PhotoPanel/>} />
          <Route path="/admin/documentpanel" element={<DocumentPanel/>} />
          <Route path="/admin/announcement" element={<Announcement/>} />
      </Routes>
    </Router>
  );
};

export default Routers;