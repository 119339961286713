import React, { useState } from 'react';
import styles from './Student.module.scss';

const Student = () => {
  // Sample student data
  const [students, setStudents] = useState([
    {
      id: 'PRE2209',
      name: 'Aaliyah',
      image: '/avatars/aaliyah.jpg',
      class: '10 A',
      dob: '2 Feb 2002',
      parentName: 'Jeffrey Wong',
      mobileNumber: '097 3584 5870',
      address: '911 Deer Ridge Drive,USA',
    },
    {
      id: 'PRE2213',
      name: 'Malynne',
      image: '/avatars/malynne.jpg',
      class: '8 A',
      dob: '3 June 2010',
      parentName: 'Fields Malynne',
      mobileNumber: '242 362 3100',
      address: 'Bacardi Rd P.O. Box N-4880, New Providence',
    },
    {
      id: 'PRE2143',
      name: 'Levell Scott',
      image: '/avatars/levell.jpg',
      class: '10 A',
      dob: '12 Apr 2002',
      parentName: 'Jeffrey Scott',
      mobileNumber: '026 7318 4366',
      address: 'P.O. Box: 41, Gaborone',
    },
    {
      id: 'PRE2431',
      name: 'Minnie',
      image: '/avatars/minnie.jpg',
      class: '11 C',
      dob: '24 Feb 2000',
      parentName: 'J Shaffer',
      mobileNumber: '952 512 4909',
      address: '4771 Oral Lake Road, Golden Valley',
    },
  ]);

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchId, setSearchId] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchPhone, setSearchPhone] = useState('');

  // Handle search
  const handleSearch = () => {
    console.log('Searching with:', { searchId, searchName, searchPhone });
    // Implement actual search logic here
  };

  return (
    <div className={styles.studentManagement}>
      <div className={styles.header}>
        <h1 className={styles.title}>Students</h1>
        <div className={styles.breadcrumb}>
          <span>Student</span>
          <span className={styles.separator}>/</span>
          <span className={styles.active}>All Students</span>
        </div>
      </div>

      <div className={styles.searchSection}>
        <input
          type="text"
          placeholder="Search by ID ..."
          className={styles.searchInput}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by Name ..."
          className={styles.searchInput}
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by Phone ..."
          className={styles.searchInput}
          value={searchPhone}
          onChange={(e) => setSearchPhone(e.target.value)}
        />
        <button className={styles.searchButton} onClick={handleSearch}>
          SEARCH
        </button>
      </div>

      <div className={styles.tableSection}>
        <div className={styles.tableHeader}>
          <h2 className={styles.tableTitle}>Students</h2>
          <div className={styles.tableActions}>
            <button className={styles.viewButton}>
              <span className={styles.listIcon}></span>
            </button>
            <button className={styles.viewButton}>
              <span className={styles.gridIcon}></span>
            </button>
            <button className={styles.downloadButton}>
              <span className={styles.downloadIcon}></span>
              Download
            </button>
            <button className={styles.addButton}>
              <span className={styles.plusIcon}></span>
            </button>
          </div>
        </div>

        <div className={styles.tableControls}>
          <div className={styles.entriesControl}>
            <span>Show</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
              className={styles.entriesSelect}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span>entries</span>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.checkboxColumn}>
                  <input type="checkbox" className={styles.checkbox} />
                </th>
                <th className={styles.sortableColumn}>
                  ID <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  Name <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  Class <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  DOB <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  Parent Name <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  Mobile Number <span className={styles.sortIcon}></span>
                </th>
                <th className={styles.sortableColumn}>
                  Address <span className={styles.sortIcon}></span>
                </th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id} className={styles.tableRow}>
                  <td>
                    <input type="checkbox" className={styles.checkbox} />
                  </td>
                  <td>{student.id}</td>
                  <td className={styles.nameColumn}>
                    <div className={styles.studentProfile}>
                      <div className={styles.avatar}>
                        <img src={student.image} alt={student.name} />
                      </div>
                      <span>{student.name}</span>
                    </div>
                  </td>
                  <td>{student.class}</td>
                  <td>{student.dob}</td>
                  <td>{student.parentName}</td>
                  <td>{student.mobileNumber}</td>
                  <td>{student.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Student;