import React from 'react';
import styles from './VissionMission.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

const VisionMission = () => {
  const downloads = [
    { id: 1, title: 'Society Members List', url: '#' },
    { id: 2, title: 'STUDENTS ATTENDANCE', url: '#' },
    { id: 3, title: 'MOU -Qem Processing', url: '#' },
    { id: 4, title: 'MOU -Alaska', url: '#' },
    { id: 5, title: 'Building Map', url: '#' },
    { id: 6, title: 'Electricity Bill', url: '#' }
  ];

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <h1 className={styles.pageTitle}>Vision & Mission</h1>
        
        <section className={styles.visionSection}>
          <h2 className={styles.sectionTitle}>Vision</h2>
          <p className={styles.sectionText}>
            To become the emerged Entrepreneur Group focusing on expanding and creating opportunities to 
            achieve corporate excellence in the competitive environment in all the sector of business.
          </p>
        </section>

        <section className={styles.missionSection}>
          <h2 className={styles.sectionTitle}>Mission</h2>
          <ul className={styles.missionList}>
            <li>Quality at all levels of functioning.</li>
            <li>Work enforcement required as per competitive corporate culture.</li>
            <li>Commitment for best Growth rate in future.</li>
            <li>Provide best environment to develop World-class human resource.</li>
          </ul>
        </section>
      </div>

      <aside className={styles.sidebar}>
        <div className={styles.feeStatement}>
          <h3>Fee Statement</h3>
          <div className={styles.feeImage}>
            <img src="/api/placeholder/400/320" alt="Fee Statement" />
          </div>
        </div>

        <div className={styles.announcement}>
          <h3 className={styles.sidebarTitle}>Announcement</h3>
          <p>Trade 1. Electrician (NSQF) 2. Fire Technology and industrial safety Management(NSQF)</p>
          <p>**********</p>
          <a href="#" className={styles.viewMore}>View more...</a>
        </div>

        <div className={styles.downloads}>
          <h3 className={styles.sidebarTitle}>Download</h3>
          <ul className={styles.downloadList}>
            {downloads.map(item => (
              <li key={item.id}>
                <a href={item.url} className={styles.downloadLink}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <a href="#" className={styles.viewMore}>View more...</a>
        </div>
      </aside>
    </div>
    <Footer/>
    </div>
  );
};

export default VisionMission;