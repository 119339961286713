// PhotoPanel.jsx
import React, { useState } from 'react';
import styles from './PhotoPanel.module.scss';
import { FaUpload, FaSave, FaTrash, FaCog, FaExpand, FaTimes } from 'react-icons/fa';
import Sidebar from '../../../Components/Sidebar/Sidebar';

const PhotoPanel = () => {
  const [images, setImages] = useState([
    { id: 1, src: '/profile-photo.jpg', title: 'Profile Photo' }
  ]);
  const [imageTitle, setImageTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleTitleChange = (e) => {
    setImageTitle(e.target.value);
  };

  const handleSave = () => {
    // Implementation for saving changes
    console.log('Saving changes with title:', imageTitle);
    if (selectedFile) {
      console.log('File selected:', selectedFile.name);
    }
  };

  const handleDeleteImage = (id) => {
    setImages(images.filter(image => image.id !== id));
  };

  return (
    <div className={styles.main}>
    <Sidebar data={"photopanel"}/>
    <div className={styles.container}>
      <div className={styles.breadcrumb}>
        <a href="/" className={styles.breadcrumbLink}>Home</a>
        <span className={styles.breadcrumbSeparator}>/</span>
        <span className={styles.breadcrumbCurrent}>All Photos</span>
      </div>

      <div className={styles.panel}>
        <div className={styles.panelHeader}>
          <div className={styles.panelTitle}>
            <span className={styles.panelIcon}>
              <FaCog />
            </span>
            Photo Panel
          </div>
          <div className={styles.panelControls}>
            <button className={styles.iconButton} title="Settings">
              <FaCog />
            </button>
            <button className={styles.iconButton} title="Expand">
              <FaExpand />
            </button>
            <button className={styles.iconButton} title="Close">
              <FaTimes />
            </button>
          </div>
        </div>

        <div className={styles.panelContent}>
          <h2 className={styles.sectionTitle}>All Images</h2>
          
          <div className={styles.imageGrid}>
            {images.map(image => (
              <div key={image.id} className={styles.imageCard}>
                <div className={styles.imageWrapper}>
                  <img src={image.src} alt={image.title} className={styles.image} />
                  <div className={styles.imageOverlay}>
                    <button 
                      className={styles.deleteButton} 
                      onClick={() => handleDeleteImage(image.id)}
                      title="Delete image"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
                <div className={styles.imageTitle}>{image.title}</div>
              </div>
            ))}
          </div>

          <div className={styles.uploadSection}>
            <div className={styles.formGroup}>
              <label htmlFor="imageTitle" className={styles.formLabel}>Image Title</label>
              <input
                type="text"
                id="imageTitle"
                value={imageTitle}
                onChange={handleTitleChange}
                className={styles.formInput}
                placeholder="Enter image title"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="selectImages" className={styles.formLabel}>Select Images</label>
              <div className={styles.fileInputWrapper}>
                <input
                  type="file"
                  id="selectImages"
                  onChange={handleFileChange}
                  className={styles.fileInput}
                  accept="image/*"
                />
                <div className={styles.customFileInput}>
                  <span className={styles.fileInputText}>
                    {selectedFile ? selectedFile.name : 'No file selected'}
                  </span>
                  <button className={styles.browseButton}>
                    <FaUpload className={styles.buttonIcon} />
                    Choose File
                  </button>
                </div>
              </div>
            </div>

            <button className={styles.saveButton} onClick={handleSave}>
              <FaSave className={styles.buttonIcon} />
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PhotoPanel;