import React from 'react';
import styles from './StateDirectorate.module.scss';
import { Building2, MapPin, Phone, Smartphone, Printer, Mail } from 'lucide-react';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';

const StateDirectorate = () => {
  const directorateInfo = {
    name: 'Directorate Of Technical Education Jodhpur(Raj.)',
    address: 'W-6 Residency Road Gaurav Path Jodhpur(Raj.)',
    contactNumber: '0291-2434395',
    mobileNumber: '',
    faxNumber: '0291-2430398',
    email: 'dte_raj@yahoo.com'
  };

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>State Directorate</h1>
        
        <div className={styles.infoContainer}>
          <div className={styles.infoRow}>
            <div className={styles.label}>
              <Building2 className={styles.icon} />
              <span>Name of Directorate:</span>
            </div>
            <div className={styles.value}>{directorateInfo.name}</div>
          </div>

          <div className={styles.infoRow}>
            <div className={styles.label}>
              <MapPin className={styles.icon} />
              <span>Address of Directorate:</span>
            </div>
            <div className={styles.value}>{directorateInfo.address}</div>
          </div>

          <div className={styles.infoRow}>
            <div className={styles.label}>
              <Phone className={styles.icon} />
              <span>Contact Number:</span>
            </div>
            <div className={styles.value}>{directorateInfo.contactNumber}</div>
          </div>

          <div className={styles.infoRow}>
            <div className={styles.label}>
              <Smartphone className={styles.icon} />
              <span>Mobile Number:</span>
            </div>
            <div className={styles.value}>{directorateInfo.mobileNumber || '-'}</div>
          </div>

          <div className={styles.infoRow}>
            <div className={styles.label}>
              <Printer className={styles.icon} />
              <span>Fax Number:</span>
            </div>
            <div className={styles.value}>{directorateInfo.faxNumber}</div>
          </div>

          <div className={styles.infoRow}>
            <div className={styles.label}>
              <Mail className={styles.icon} />
              <span>Email Address:</span>
            </div>
            <div className={styles.value}>
              <a href={`mailto:${directorateInfo.email}`} className={styles.email}>
                {directorateInfo.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default StateDirectorate;