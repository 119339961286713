import React from "react";
import styles from "./DocumentPanel.module.scss";
import { FaTrash } from "react-icons/fa";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const DocumentPanel = () => {
  return (
    <div className={styles.main}>
    <Sidebar data={"documentpanel"}/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Documents Panel</h2>
        <input
          type="text"
          placeholder="Search..."
          className={styles.search}
        />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Doc ID</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {["DGET Order", "Society Registration", "Society Bylaws", "Site Plan Of Existing Building"].map((title, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{index + 1}</td>
              <td className={styles.title}>{title}</td>
              <td>
                <button className={styles.deleteButton}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button className={styles.pageButton}>&larr; Previous</button>
        <span>1</span>
        <button className={styles.pageButton}>Next &rarr;</button>
      </div>
      <div className={styles.form}>
        <input type="text" placeholder="Document Title" className={styles.input} />
        <input type="file" className={styles.fileInput} />
        <button className={styles.saveButton}>Save changes</button>
      </div>
    </div>
    </div>
  );
};

export default DocumentPanel;
