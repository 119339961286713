import React from 'react';
import styles from './Infrastructure.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
const Infrastructure = () => {
  const infrastructureData = [
    { id: 1, name: 'Total Area', area: 3000 },
    { id: 2, name: 'Covered Area', area: 1024 },
    { id: 3, name: 'Total Class Rooms Area', area: 298.66 },
    { id: 4, name: 'Total Workshops Area', area: 256 },
    { id: 5, name: 'Drawing Hall', area: 128 },
    { id: 6, name: 'Audio Visual Hall', area: 1 },
    { id: 7, name: 'Computer Lab', area: 1 },
    { id: 8, name: 'Play Area', area: 1930.5 },
    { id: 9, name: 'Library', area: 1 },
    { id: 10, name: 'Remarks', area: 'OK' }
  ];

  const tradeData = [
    {
      id: 1,
      tradeName: 'ELECTRICIAN',
      unit: 3,
      classRoomsPerUnit: 20.06,
      totalClassRooms: 2,
      workshopAreaPerUnit: 256,
      totalWorkshopArea: 1
    },
    {
      id: 2,
      tradeName: 'HEALTH SECURITY INSPECTOR (NSQF)',
      unit: 1,
      classRoomsPerUnit: 20.6,
      totalClassRooms: 1,
      workshopAreaPerUnit: 100,
      totalWorkshopArea: 1
    }
  ];

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <section className={styles.section}>
        <h1 className={styles.title}>Infrastructure</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Ownership Document / Lease Agreement for Rented Building</th>
                <th>In Square Meters</th>
                <th>Link to Scanned Document / Image</th>
              </tr>
            </thead>
            <tbody>
              {infrastructureData.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.area}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.section}>
        <h2 className={styles.title}>Trade Infra</h2>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Trade Name</th>
                <th>Unit</th>
                <th>Class Rooms Area Per Unit</th>
                <th>Total Class Rooms Area for Trade</th>
                <th>Workshop Area Per Unit</th>
                <th>Total Workshop Area for Trade</th>
                <th>Related Document</th>
              </tr>
            </thead>
            <tbody>
              {tradeData.map((trade) => (
                <tr key={trade.id}>
                  <td>{trade.tradeName}</td>
                  <td>{trade.unit}</td>
                  <td>{trade.classRoomsPerUnit}</td>
                  <td>{trade.totalClassRooms}</td>
                  <td>{trade.workshopAreaPerUnit}</td>
                  <td>{trade.totalWorkshopArea}</td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default Infrastructure;