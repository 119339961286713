import React from 'react'
import Header from '../../Components/Header/Header'
import { FileText, Download, ChevronRight } from 'lucide-react';
import styles from "./Home.module.scss"
import Footer from '../../Components/Footer/Footer';
import Slider from '../../Components/Slider/Slider';
// import NewUi from './NewUi';


const Home = () => {
  return<>
  {/* <NewUi/> */}
      <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.welcome}>
        <h1 className={styles.welcomeTitle}>
          Welcome to <span>Virendra PVT ITI, Bagri</span>
        </h1>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.contentLeft}>
          <div className={styles.introduction}>
            <h2 className={styles.introductionTitle}>INTRODUCTION</h2>
            <p className={styles.introductionText}>
              Industrial Training Institute are training institute which provide training in technical
              field and constituted under Directorate General of Employment & Training (DGET) Ministry of
              Labor & Employment, Union Government of India.
            </p>
            <p className={styles.introductionText}>
              We believe that a technical career often provides a better salary, working conditions, and
              self image, which consequently result in a better life. With this belief, we exist to help
              men and women attain that better life.
            </p>
          </div>

          <div className={styles.aboutSection}>
            <h2 className={styles.aboutSectionTitle}>About Institute</h2>
            <table className={styles.aboutSectionTable}>
              <tbody>
                <tr>
                  <th>Name of the Institute</th>
                  <td>Virendra PVT ITI, Bagri </td>
                </tr>
                <tr>
                  <th>Address of the Institute</th>
                  <td> VPO-Bagri, Laxmangarh (Sikar), 332311</td>
                </tr>
                {/* <tr>
                  <th>Date of Establishment</th>
                  <td>15/12/2016</td>
                </tr> */}
                {/* <tr>
                  <th>DGET File Reference No.</th>
                  <td>DGET-6/20/131/2016-TC</td>
                </tr> */}
                <tr>
                  <th>Code Allotted by DGET</th>
                  <td>PR08001754</td>
                </tr>
                <tr>
                  <th>Contact No</th>
                  <td>7690032342, 8003031934</td>
                </tr>
                <tr>
                  <th>E-mail ID</th>
                  <td>virendraitibagri@gmail.com</td>
                </tr>
                <tr>
                  <th>Location Rural / Urban</th>
                  <td>Rural</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.sidebar}>
          <div className={styles.sidebarSection}>
            <h3 className={styles.sidebarTitle}>Announcement</h3>
            <div className={styles.sidebarContent}>
              {/* <div className={styles.feeStatement}>Fee Statement</div> */}
              <p>Trade 1. Electrician (NSQF)</p>
              <p>2. Health Sanitary Inspector (NSQF)</p>
            </div>
          </div>

          <div className={styles.sidebarSection}>
            <h3 className={styles.sidebarTitle}>Download</h3>
            <div className={styles.sidebarContent}>
              <ul className={styles.sidebarList}>
                <li>
                  <a href="#">
                    <FileText size={16} />
                    Society Members List
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FileText size={16} />
                    STUDENTS ATTENDANCE
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FileText size={16} />
                    MOU -Qem Processing
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FileText size={16} />
                    Building Map
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FileText size={16} />
                    Electricity Bill
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </>
}

export default Home
