import React from 'react'
import styles from "./Slider.module.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import home from "../../assests/Home.jpg"
import main2 from "../../assests/main2.jpg"
const images = [
  home,
  main2
    // "https://source.unsplash.com/800x400/?mountains",
    // "https://source.unsplash.com/800x400/?city",
  ];
const Slider = () => {
  return (
    <div>
      <section className={styles.hero}>
      <div className={styles.heroContainer}>
      <div className={styles.sliderContainer}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 7000 }}
        loop
      >
        {images.map((src, index) => (
          <SwiperSlide key={index}>
            <img src={src} alt={`Slide ${index + 1}`} className={styles.image}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
    </section>
    </div>
  )
}

export default Slider
