// MenuManagement.jsx
import React, { useState } from 'react';
import { Pencil, Trash2, User, Plus } from 'lucide-react';
import styles from './MenuManager.module.scss';
import Slider from '../../../Components/Slider/Slider';
import Sidebar from '../../../Components/Sidebar/Sidebar';

const MenuManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  // Sample data (you would fetch this from an API in a real app)
  const menuItems = [
    { id: 33, name: 'Contact Us', link: 'contact-us.php', serialNo: 1 },
    { id: 32, name: 'Gallery', link: 'gallery.php', serialNo: 2 },
    { id: 31, name: 'Consumption', link: '#', serialNo: 3 },
    { id: 30, name: 'More', link: '#', serialNo: 4 },
    { id: 29, name: 'Facilities', link: '#', serialNo: 5 },
    { id: 28, name: 'Staff', link: '#', serialNo: 6 },
    { id: 27, name: 'Trades', link: '#', serialNo: 7 },
    { id: 26, name: 'Students', link: '#', serialNo: 8 },
    { id: 18, name: 'About', link: '#', serialNo: 9 },
    { id: 17, name: 'Home', link: 'index.php', serialNo: 10 },
  ];

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle records per page change
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value, 10));
  };

  // Filter menu items based on search term
  const filteredMenuItems = menuItems.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.link.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.id.toString().includes(searchTerm)
  );

  return (
    <div className={styles.main}>
    <Sidebar data={"managemanagement"} />
    <div className={styles.menuManagement}>
      <div className={styles.header}>
        <div className={styles.title}>
          <User size={20} />
          <h1>All Main Menu</h1>
        </div>
        <button className={styles.newMenuButton}>
          <Plus size={16} />
          New Menu
        </button>
      </div>

      <div className={styles.controls}>
        <div className={styles.recordsPerPage}>
          <select 
            value={recordsPerPage} 
            onChange={handleRecordsPerPageChange}
            className={styles.select}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span className={styles.recordsLabel}>records per page</span>
        </div>
        
        <div className={styles.search}>
          <label htmlFor="search">Search:</label>
          <input
            id="search"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles.searchInput}
            placeholder="Search menus..."
          />
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.serialColumn}>S. No.</th>
              <th>Main Menu ID</th>
              <th>Menu Name</th>
              <th>Link</th>
              <th className={styles.actionColumn}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredMenuItems.map((item) => (
              <tr key={item.id}>
                <td>{item.serialNo}</td>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.link}</td>
                <td className={styles.actions}>
                  <button className={styles.editButton}>
                    <Pencil size={16} />
                  </button>
                  <span className={styles.separator}>|</span>
                  <button className={styles.deleteButton}>
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.pagination}>
        <p>Showing 1 to {filteredMenuItems.length} of {filteredMenuItems.length} entries</p>
      </div>
    </div>
    </div>
  );
};

export default MenuManagement;