import React from 'react';
import styles from './IndustryVisit.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const IndustryVisit = () => {
  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Industry Visit</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>
                S.No.
                </th>
                <th className={styles.headerCell}>
                Session
                </th>
                <th className={styles.headerCell}>
                Date
                </th>
                <th className={styles.headerCell}>
                Name of Organisation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRow}>
                <td className={styles.tableCell}>1</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
                <td className={styles.tableCell}>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default IndustryVisit;