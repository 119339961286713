import React from 'react';
import styles from './Grievance.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const Grievance = () => {
  const rtiData = [
    {
      role: 'CPIO',
      name: 'RAKESH KUMAR',
      designation: 'ADMINISTRATIVE',
      contactNo: '7976217795'
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Right to Information</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>Appellate officer Name</th>
                <th className={styles.headerCell}>Designation</th>
                <th className={styles.headerCell}>Contact No.</th>
              </tr>
            </thead>
            <tbody>
              {rtiData.map((officer, index) => (
                <tr key={index} className={styles.tableRow}>
                  <td className={styles.tableCell}>
                    <span className={styles.role}>{officer.role}</span>
                    <span className={styles.name}>{officer.name}</span>
                  </td>
                  <td className={styles.tableCell}>{officer.designation}</td>
                  <td className={styles.tableCell}>{officer.contactNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Grievance;