import React from 'react';
import styles from './ComputerLab.module.scss';
import { Monitor } from 'lucide-react';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const ComputerLab = () => {
  const inventoryData = [
    {
      name: 'Computer',
      quantity: 10,
      supplier: 'Quality ITI Sikar'
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <Monitor className={styles.headerIcon} size={24} />
        <h2 className={styles.title}>Computer Lab</h2>
      </div>
      
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>Name Of Items</th>
              <th className={styles.th}>Quantity</th>
              <th className={styles.th}>Name and address of supplier</th>
            </tr>
          </thead>
          <tbody>
            {inventoryData.map((item, index) => (
              <tr key={index} className={styles.tr}>
                <td className={styles.td}>
                  <div className={styles.itemName}>
                    <Monitor size={18} className={styles.itemIcon} />
                    {item.name}
                  </div>
                </td>
                <td className={styles.td}>
                  <span className={styles.quantity}>{item.quantity}</span>
                </td>
                <td className={styles.td}>{item.supplier}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ComputerLab