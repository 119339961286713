import React from 'react';
import styles from './ElectricalConsumption.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const ElectricalDashboard = () => {
  const consumptionData = [
    {
      id: 1,
      month: 'May',
      consumption: 8
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.dashboard}>
      <div className={styles.content}>
        <header className={styles.header}>
          <h1>Electrical Consumption</h1>
        </header>

        <section className={styles.dataSection}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <h2>Electrical Energy Consumption per student per month</h2>
            </div>
            
            <div className={styles.tableWrapper}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Month</th>
                    <th>Consumption (per month per student)</th>
                  </tr>
                </thead>
                <tbody>
                  {consumptionData.map(item => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.month}</td>
                      <td>{item.consumption}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ElectricalDashboard;