import React from "react";
import styles from "./Announcement.module.scss";
import { FaTrash, FaSearch } from "react-icons/fa";
import Sidebar from "../../../Components/Sidebar/Sidebar";

const Announcement = () => {
  return (
    <div className={styles.main}>
    <Sidebar data={"announcement"}/>
    <div className={styles.container}>
      <header className={styles.header}>
        <h2>📢 Announcement Panel</h2>
        <div className={styles.searchContainer}>
          <FaSearch className={styles.searchIcon} />
          <input type="text" placeholder="Search..." className={styles.searchInput} />
        </div>
      </header>
      
      <table className={styles.table}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Doc ID</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>1</td>
            <td className={styles.link}>Admission Open</td>
            <td>
              <button className={styles.deleteButton}>
                <FaTrash />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      
      <div className={styles.pagination}>
        <button className={styles.pageButton}>&larr; Previous</button>
        <span>1</span>
        <button className={styles.pageButton}>Next &rarr;</button>
      </div>
      
      <form className={styles.form}>
        <label>Announcement Title</label>
        <input type="text" className={styles.input} />
        
        <label>Select Document</label>
        <input type="file" className={styles.fileInput} />
        
        <button className={styles.saveButton}>Save Changes</button>
      </form>
    </div>
    </div>
  );
};

export default Announcement;
