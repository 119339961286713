import React from 'react';
import styles from './MaintenanceExpenditure.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const MaintenanceExpenditure = () => {
  const consumptionData = [
    {
      id: 1,
      year: 'First Year',
      tradeName: 'Electrician',
      consumption: '425',
    },
    {
      id: 2,
      year: 'First Year',
      tradeName: 'Fire and Industrial Safety Management',
      consumption: '225',
    },
    {
      id: 3,
      year: 'Second Year',
      tradeName: 'Electrician',
      consumption: '425',
    }
  ];

  return (
    <div>
      <Header/>
      <Slider/>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>Maintenance Expenditure</h1>
          
          <div className={styles.sectionHeader}>
          Maintenance expenditure per year on building and machines
          </div>
          
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.headerCell}>S. No.</th>
                  <th className={styles.headerCell}>Year</th>
                  <th className={styles.headerCell}>Trade Name</th>
                  <th className={styles.headerCell}>Consumption (per month per trainee)</th>
                </tr>
              </thead>
              <tbody>
                {consumptionData.map((item) => (
                  <tr key={item.id} className={styles.tableRow}>
                    <td className={styles.tableCell}>{item.id}</td>
                    <td className={styles.tableCell}>{item.year}</td>
                    <td className={styles.tableCell}>{item.tradeName}</td>
                    <td className={`${styles.tableCell} ${styles.consumptionCell}`}>
                      {item.consumption}
                    </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default MaintenanceExpenditure;