import React from 'react';
import styles from './Schemes.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const Schemes = () => {
  const schemes = [
    {
      name: "YEARLY",
      capacity: "192",
      duration: "2/1",
      admitted: "72/48",
      status: "ADMITTED"
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.header}>
          <h2 className={styles.title}>Schemes</h2>
        </div>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name of the Scheme</th>
                <th>Total Capacity</th>
                <th>Duration</th>
                <th>Admitted in current Financial Year</th>
                <th>Current Status</th>
              </tr>
            </thead>
            <tbody>
              {schemes.map((scheme, index) => (
                <tr key={index}>
                  <td>{scheme.name}</td>
                  <td>{scheme.capacity}</td>
                  <td>{scheme.duration}</td>
                  <td>{scheme.admitted}</td>
                  <td>
                    <span className={styles.statusBadge}>
                      {scheme.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Schemes;
