// OptionManager.jsx
import React, { useState } from 'react';
import styles from './OptionManager.module.scss';
import Sidebar from '../../../Components/Sidebar/Sidebar';

const OptionManager = () => {
  // Initial form state
  const [formData, setFormData] = useState({
    socialLinks: {
      facebook: 'http://www.facebook.com',
      twitter: 'http://www.twitter.com',
      linkedin: 'http://www.linkedin.com',
      googlePlus: 'http://plus.google.com',
      pinterest: 'http://www.pinterest.com',
      youtube: 'http://www.youtube.com'
    },
    logo: null,
    siteTitle: 'G B PRIVATE ITI, FATEHPUR SHEK',
    email: 'gbpiti2016@gmail.com',
    address: 'DOLTABAD, PO- FATEHPUR\nSHEKHAWTI DISTT SIKAR\nRAJASTHAN',
    contactNo: '6350658040, 7891617431, 8302489',
    googleMapEmbed: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d745.2721166316122!2d74.9658120731771!3d28.01832967242230!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3914a90045a171fd%3A0xb42f06eb57cd2d96!2s!5e0!3m2!1sen!2sin!4v1554202587723!5m2!1sen!2sin"></iframe>'
  });

  // Handle input changes
  const handleInputChange = (section, field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [section]: section === 'socialLinks' 
        ? { ...prevData.socialLinks, [field]: value }
        : value
    }));
  };

  // Handle file selection
  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFormData(prevData => ({
        ...prevData,
        logo: e.target.files[0]
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Saving changes:', formData);
    // API call would go here
    alert('Changes saved successfully!');
  };

  // Toggle panel visibility
  const [isPanelExpanded, setIsPanelExpanded] = useState(true);
  const togglePanel = () => setIsPanelExpanded(!isPanelExpanded);

  return (
    <div className={styles.main}>
    <Sidebar data={"optionmanager"}/>
    <div className={styles.editPanelContainer}>
      <div className={styles.panelHeader} onClick={togglePanel}>
        <span className={styles.expandIcon}>{isPanelExpanded ? '−' : '+'}</span>
        <h2 className={styles.panelTitle}>Site Option Edit Panel</h2>
        <div className={styles.headerControls}>
          <button className={styles.headerButton} title="Settings">⚙️</button>
          <button className={styles.headerButton} title="Minimize">▽</button>
          <button className={styles.headerButton} title="Close">✕</button>
        </div>
      </div>

      {isPanelExpanded && (
        <form className={styles.editForm} onSubmit={handleSubmit}>
          {/* Social Site Links Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Social Site Links</div>
            <div className={styles.sectionContent}>
              {Object.entries({
                facebook: 'Facebook',
                twitter: 'Twitter',
                linkedin: 'LinkedIn',
                googlePlus: 'Google Plus',
                pinterest: 'Pinterest',
                youtube: 'YouTube'
              }).map(([key, label]) => (
                <div className={styles.inputGroup} key={key}>
                  <input
                    type="text"
                    className={styles.inputField}
                    value={formData.socialLinks[key]}
                    onChange={(e) => handleInputChange('socialLinks', key, e.target.value)}
                  />
                  <label className={styles.inputLabel}>{label}</label>
                </div>
              ))}
            </div>
            <div className={styles.sectionHelp}>
              Enter your social sites link to their relevant input box
            </div>
          </section>

          {/* Website Logo Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Website Logo</div>
            <div className={styles.sectionContent}>
              <div className={styles.fileUpload}>
                <span className={styles.fileName}>
                  {formData.logo ? formData.logo.name : 'No file selected'}
                </span>
                <label className={styles.fileButton}>
                  Choose File
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleFileSelect} 
                    className={styles.hiddenFileInput}
                  />
                </label>
              </div>
            </div>
            <div className={styles.sectionHelp}>Select site logo</div>
          </section>

          {/* Site Title Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Site Title</div>
            <div className={styles.sectionContent}>
              <input
                type="text"
                className={styles.inputField}
                value={formData.siteTitle}
                onChange={(e) => handleInputChange('siteTitle', null, e.target.value)}
              />
            </div>
            <div className={styles.sectionHelp}>Contact No.</div>
          </section>

          {/* Email Address Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Email Address</div>
            <div className={styles.sectionContent}>
              <input
                type="email"
                className={styles.inputField}
                value={formData.email}
                onChange={(e) => handleInputChange('email', null, e.target.value)}
              />
            </div>
            <div className={styles.sectionHelp}>Email address</div>
          </section>

          {/* Address Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Address</div>
            <div className={styles.sectionContent}>
              <textarea
                className={styles.textareaField}
                value={formData.address}
                onChange={(e) => handleInputChange('address', null, e.target.value)}
                rows={4}
              />
            </div>
            <div className={styles.sectionHelp}>Address</div>
          </section>

          {/* Contact No. Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Contact No.</div>
            <div className={styles.sectionContent}>
              <input
                type="text"
                className={styles.inputField}
                value={formData.contactNo}
                onChange={(e) => handleInputChange('contactNo', null, e.target.value)}
              />
            </div>
            <div className={styles.sectionHelp}>Contact No.</div>
          </section>

          {/* Google Map Embed Url Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}>Google Map Embed Url</div>
            <div className={styles.sectionContent}>
              <textarea
                className={styles.textareaField}
                value={formData.googleMapEmbed}
                onChange={(e) => handleInputChange('googleMapEmbed', null, e.target.value)}
                rows={5}
              />
            </div>
            <div className={styles.sectionHelp}>Google map embed code</div>
          </section>

          {/* Save Button Section */}
          <section className={styles.formSection}>
            <div className={styles.sectionLabel}></div>
            <div className={styles.sectionContent}>
              <button type="submit" className={styles.saveButton}>
                Save changes
              </button>
            </div>
            <div className={styles.sectionHelp}></div>
          </section>
        </form>
      )}
    </div>
    </div>
  );
};

export default OptionManager;