import React from 'react';
import styles from './ApplicationFormat.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

const ApplicationFormat = () => {
  // Sample data - replace with actual data
  const certificateData = [
    {
     
    },
    {
      
    }
  ];

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <h1 className={styles.title}>Application Format</h1>
      
      <div className={styles.tableWrapper}>
        {/* <div className={styles.tableHeader}>
          <h2>Certificates Issued to Trainees</h2>
        </div> */}
        
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name Of Course</th>
                <th>Duration</th>
                <th>Tentative Date</th>
                <th>When To Apply</th>
                <th>Application Format</th>
              </tr>
            </thead>
            <tbody>
              {certificateData.map((row, index) => (
                <tr key={index}>
                  <td>{row.yearMonth}</td>
                  <td>{row.appeared}</td>
                  <td>{row.passed}</td>
                  <td>{row.issued}</td>
                  <td>{row.pending}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ApplicationFormat;