import React from "react";
import styles from "./Gallery.module.scss"; // SCSS module
import Header from "../../Components/Header/Header";
import Slider from "../../Components/Slider/Slider";
import Footer from "../../Components/Footer/Footer";
import Home from "../../assests/Home.jpg"
import main2 from "../../assests/main2.jpg"
import image1 from "../../assests/image1.jpg"
import image2 from "../../assests/image2.jpg"
import image3 from "../../assests/image3.jpg"
import image4 from "../../assests/image4.jpg"
import image5 from "../../assests/image5.jpg"
import image6 from "../../assests/image6.jpg"
import image7 from "../../assests/image7.jpg"
import image8 from "../../assests/image8.jpg"
import image9 from "../../assests/image9.jpg"
import image10 from "../../assests/image10.jpg"
import image11 from "../../assests/image11.jpg"
import image12 from "../../assests/image12.jpg"

const images = [
    Home,main2,image1,image2,image3,image4,image5,image6,image7,image8,image9,image10,image11,image12
   
];

const Gallery = () => {
  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Welcome to <span className={styles.highlight}>QUALITY PVT. ITI, SIKAR</span>
      </h1>
      <div className={styles.gallery}>
        {images.map((src, index) => (
          <div key={index} className={styles.imageWrapper}>
            <img src={src} alt={`Gallery ${index + 1}`} className={styles.image} />
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Gallery;