import React from 'react';
import styles from './Certification.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

const Certification = () => {
  // Sample data - replace with actual data
  const certificateData = [
    {
      yearMonth: "2024-01",
      appeared: 120,
      passed: 110,
      issued: 105,
      pending: 5
    },
    {
      yearMonth: "2024-02",
      appeared: 150,
      passed: 140,
      issued: 135,
      pending: 5
    }
  ];

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <h1 className={styles.title}>Certification</h1>
      
      <div className={styles.tableWrapper}>
        <div className={styles.tableHeader}>
          <h2>Certificates Issued to Trainees</h2>
        </div>
        
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Year-Month</th>
                <th>No. of Trainees Appeared</th>
                <th>No. of Trainees Passed</th>
                <th>No. of NTCs Issued</th>
                <th>No. of NTCs Pending</th>
              </tr>
            </thead>
            <tbody>
              {certificateData.map((row, index) => (
                <tr key={index}>
                  <td>{row.yearMonth}</td>
                  <td>{row.appeared}</td>
                  <td>{row.passed}</td>
                  <td>{row.issued}</td>
                  <td>{row.pending}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Certification;