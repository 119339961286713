// StudentDetails.jsx
import React from 'react';
import styles from './StudentDetails.module.scss';

const StudentDetails = () => {
    const userData = {
        name: "Bruce Willis",
        department: "Computer Science",
        avatar: "/path/to/avatar.jpg",
        stats: [
          { id: 1, label: "Followers", value: 2850 },
          { id: 2, label: "Following", value: 346 },
          { id: 3, label: "Posts", value: 189 }
        ],
        mobile: "+91 89657 48512",
        email: "[email protected]",
        gender: "Male",
        birthDate: "22 Apr 1995",
        languages: ["English", "French", "Bangla"]
      };
  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.profileHeader}>
        <div className={styles.avatarContainer}>
          <img src={userData.avatar} alt={`${userData.name}'s avatar`} className={styles.avatar} />
        </div>
        <div className={styles.nameSection}>
          <h2 className={styles.name}>{userData.name}</h2>
          <p className={styles.department}>{userData.department}</p>
        </div>
      </div>
      
      <div className={styles.statsContainer}>
        {userData.stats.map((stat) => (
          <div key={stat.id} className={styles.statItem}>
            <span className={styles.statLabel}>{stat.label}</span>
            <span className={styles.statValue}>{stat.value}</span>
          </div>
        ))}
      </div>
      
      <div className={styles.actionButtons}>
        <button className={styles.followButton}>Follow</button>
        <button className={styles.messageButton}>Message</button>
      </div>
      
      <div className={styles.detailsSection}>
        <h3 className={styles.sectionTitle}>Personal Details:</h3>
        <ul className={styles.detailsList}>
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>👤</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Name</span>
              <span className={styles.detailValue}>{userData.name}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>🏢</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Department</span>
              <span className={styles.detailValue}>{userData.department}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>📱</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Mobile</span>
              <span className={styles.detailValue}>{userData.mobile}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>✉️</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Email</span>
              <span className={styles.detailValue}>{userData.email}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>⚧</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Gender</span>
              <span className={styles.detailValue}>{userData.gender}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>🎂</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Date of Birth</span>
              <span className={styles.detailValue}>{userData.birthDate}</span>
            </div>
          </li>
          
          <li className={styles.detailItem}>
            <span className={styles.detailIcon}>🗣️</span>
            <div className={styles.detailContent}>
              <span className={styles.detailLabel}>Language</span>
              <span className={styles.detailValue}>{userData.languages.join(', ')}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StudentDetails;