import React from 'react';
import styles from './ElectricPower.module.scss';
import { FileText } from 'lucide-react';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';

const PowerSupplyDetails = () => {
  const powerData = {
    load: '10 kw',
    connectionDate: '01/06/2016',
    connectionName: 'm/s Quality shiksha',
    meterNo: '2633834',
    sealNo: '24',
    remarks: 'ok'
  };

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Electric Power Supply</h2>
      </div>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.row}>
            <div className={styles.label}>Present Electric Load</div>
            <div className={styles.value}>{powerData.load}</div>
          </div>
          
          <div className={styles.row}>
            <div className={styles.label}>Date of Connection</div>
            <div className={styles.value}>{powerData.connectionDate}</div>
          </div>
          
          <div className={styles.row}>
            <div className={styles.label}>Connection in the name of</div>
            <div className={styles.value}>{powerData.connectionName}</div>
          </div>
          
          <div className={styles.row}>
            <div className={styles.label}>Meter No.</div>
            <div className={styles.value}>{powerData.meterNo}</div>
          </div>
          
          <div className={styles.row}>
            <div className={styles.label}>Seal No.</div>
            <div className={styles.value}>{powerData.sealNo}</div>
          </div>
          
          <div className={styles.row}>
            <div className={styles.label}>Remarks</div>
            <div className={styles.valueWithAction}>
              <span className={styles.status}>{powerData.remarks}</span>
              <button className={styles.button}>
                <FileText size={16} className={styles.icon} />
                View Bill Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default PowerSupplyDetails;