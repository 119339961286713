import React from 'react';
import { MapPin, Phone, Mail, Plus, Minus, Clock } from 'lucide-react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <section className={styles.locationWrapper}>
      <div className={styles.mainWrapper}>
        <div className={styles.contentLayout}>
          <div className={styles.mapBlock}>
            <h2 className={styles.mapBlockHeader}>Google Map Location</h2>
            <div className={styles.mapBlockFrame}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d441.01116800821626!2d74.96138694481257!3d27.837788811103458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396b55537f47ef3b%3A0x926d6e51000b37a2!2sVerendra%20Bal%20Niketan%20Vidya%20Sr.%20Sec.%20School!5e0!3m2!1sen!2sin!4v1740226701357!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className={styles.mapBlockButtons}>
                <button className={styles.mapBlockButtonsControl} aria-label="Zoom in">
                  <Plus size={18} />
                </button>
                <button className={styles.mapBlockButtonsControl} aria-label="Zoom out">
                  <Minus size={18} />
                </button>
              </div>
            </div>
          </div>

          <div className={styles.infoBlock}>
            <h2 className={styles.infoBlockHeader}>Get in Touch with Us</h2>
            <div className={styles.infoBlockList}>
              <div className={styles.infoBlockRow}>
                <MapPin className={styles.infoBlockRowIcon} size={24} />
                <div className={styles.infoBlockRowData}>
                  <span className={styles.infoBlockRowLabel}>Address</span>
                  <span className={styles.infoBlockRowValue}>
                  VPO-Bagri, Laxmangarh (Sikar), 332311
                  </span>
                </div>
              </div>

              <div className={styles.infoBlockRow}>
                <Phone className={styles.infoBlockRowIcon} size={24} />
                <div className={styles.infoBlockRowData}>
                  <span className={styles.infoBlockRowLabel}>Phone</span>
                  <span className={styles.infoBlockRowValue}>
                  7690032342 , 8003031934
                  </span>
                </div>
              </div>

              <div className={styles.infoBlockRow}>
                <Mail className={styles.infoBlockRowIcon} size={24} />
                <div className={styles.infoBlockRowData}>
                  <span className={styles.infoBlockRowLabel}>Email</span>
                  <span className={styles.infoBlockRowValue}>
                  virendraitibagri@gmail.com
                  </span>
                </div>
              </div>

              <div className={styles.infoBlockRow}>
                <Clock className={styles.infoBlockRowIcon} size={24} />
                <div className={styles.infoBlockRowData}>
                  <span className={styles.infoBlockRowLabel}>Last Update</span>
                  <span className={styles.infoBlockRowValue}>11.02.2025</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={styles.bottomBar}>
          <span className={styles.bottomBarCaption}>Developed by</span>
          <a href="#" className={styles.bottomBarCredit}>Marwal Infotech</a>
        </div> */}
      </div>
    </section>
  );
};

export default Footer;