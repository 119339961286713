import React from 'react';
import styles from './AchiveTrainees.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const AchievementsTable = () => {
  const achievements = [
    {
      award: "Best Performance Award",
      scheme: "Technical Excellence Program",
      winner: "John Smith",
      year: "2024",
      remarks: "Outstanding contribution"
    },
    {
      award: "Innovation Star",
      scheme: "Digital Innovation Track",
      winner: "Sarah Johnson",
      year: "2024",
      remarks: "Novel solution development"
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.header}>
          <h2 className={styles.title}>Achievements Trainees</h2>
        </div>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Name of the award</th>
                <th>Under Scheme</th>
                <th>Won by</th>
                <th>Year</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {achievements.map((achievement, index) => (
                <tr key={index}>
                  <td>{achievement.award}</td>
                  <td>{achievement.scheme}</td>
                  <td>{achievement.winner}</td>
                  <td>{achievement.year}</td>
                  <td>{achievement.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default AchievementsTable;