import React from 'react';
import styles from './StaffAttendance.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const StaffAttendance = () => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const rows = Array.from({ length: 7 }, (_, index) => index + 1);

  return (<div>
  <Header/>
  <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Staff Attendance</h2>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>S.No.</th>
              <th className={styles.th}>Name of Instructor</th>
              <th className={styles.th}>Year</th>
              {months.map((month) => (
                <th key={month} className={styles.th}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((rowNum) => (
              <tr key={rowNum} className={styles.tr}>
                <td className={styles.td}>{rowNum}</td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                {months.map((month) => (
                  <td key={month} className={styles.td}></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default StaffAttendance;