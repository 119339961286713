import React, { useState } from 'react';
import { Mail, Phone, ChevronDown } from 'lucide-react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const images = [
    "https://quality.pvtiti.com/gallery/slider/6787ef557f902.jpg",
    "https://quality.pvtiti.com/gallery/slider/6787ef269cee5.jpeg",
    // "https://source.unsplash.com/800x400/?mountains",
    // "https://source.unsplash.com/800x400/?city",
  ];
const navItems = [
  {
    id: 'about',
    title: 'About',
    dropdown: [
      { id: 'Infrastructure', name: 'Infrastructure' },
      { id: 'managing-trust', name: 'Managing Trust' },
      { id: 'vision-mission', name: 'Vision & Mission' },
      { id: 'state-directorate', name: 'State Directorate' },
      { id: 'institute-rating', name: 'Institute Rating' },
      { id: 'ISO', name: 'ISO' },
      { id: 'court-case', name: 'Court Case & Status' },

    ]
  },
  {
    id: 'students',
    title: 'Students',
    dropdown: [
      { id: 'attendance-record', name: 'Attendance Record' },
      { id: 'progress-card', name: 'Progress card' },
      { id: 'certification', name: 'Certification' },
      { id: 'trainee-record', name: 'Trainee Record' },
      { id: 'admission-criteria', name: 'Admission Criteria' },
      { id: 'application-format', name: 'Application Format' },
      { id: 'placements', name: 'Placements' },
      { id: 'results', name: 'Results' },
      { id: 'achievements-trainee', name: 'Achievements Trainee' }
      
    ]
  },
  {
    id: 'trades',
    title: 'Trades',
    dropdown: [
      { id: 'schemes', name: 'Schemes' },
      { id: 'trades', name: 'Trades' },
    ]
  },
  {
    id: 'staff',
    title: 'Staff',
    dropdown: [
      { id: 'technical-staff', name: 'Technical Staff' },
      { id: 'administrative-staff', name: 'Administrative Staff' },
      { id: 'staff-attendance', name: 'Staff Attendance' },
      { id: 'awards-instructor', name: 'Awards of Instructor' },

    ]
  },
  {
    id: 'facilities',
    title: 'Facilities',
    dropdown: [
      { id: 'electric-supply', name: 'Electric Power Supply' },
      { id: 'computer-lab', name: 'Computer Lab' },
      { id: 'library', name: 'Library' },
      { id: 'sports', name: 'Sports' },
      { id: 'industry-linkages', name: 'Industry Institute Linkages' },
      { id: 'industry-visit', name: 'Industry Visit' },
      { id: 'extra-activities', name: 'Extra Curricular Activities' },
    ]
  },
  {
    id: 'more',
    title: 'More',
    dropdown: [
      { id: 'RTI', name: 'RTI' },
      { id: 'funds-details', name: 'Funds Details' },
      { id: 'DGET', name: 'DGET order' },
      { id: 'Grie', name: 'Grie. Redr. Mechanism' }
    ]
  },
  {
    id: 'Consumption',
    title: 'Consumption',
    dropdown: [
      { id: 'raw-consumption', name: 'Raw Material Consumption' },
      { id: 'electrical-consumption', name: 'Electrical Consumption' },
      { id: 'maintenance-expenditure', name: 'Maintenance Expenditure' },
    ]
  }
];

const Header = () => {


  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setActiveDropdown(index);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const handleDropdownClick = (parentId, childId) => {
    navigate(`/${parentId}/${childId}`);
  };

  return (
    <div className={styles.container}>
      {/* Top Info Bar */}
      <div className={styles.topBar}>
        <div className={styles.topBarContainer}>
          <div className={styles.topBarContact}>
            <div className={styles.topBarContactItem}>
              <Phone />
              <span>7690032342, 8003031934</span>
            </div>
            <div className={styles.topBarContactItem}>
              <Mail />
              <span>virendraitibagri@gmail.com</span>
            </div>
          </div>
        </div>
      </div>

      {/* Header */}
      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <div className={styles.headerLogo}>
            <div className={styles.headerLogoTitle}>Virendra PVT ITI Bagri</div>
            {/* <div className={styles.headerLogoSubtitle}>Industrial Training Institute</div> */}
          </div>
         
        </div>
      </header>

      {/* Navigation */}
      <nav className={styles.nav}>
        <div className={styles.navContainer}>
          <div className={styles.navList}>
            <NavLink to="/" className={styles.navItem}>Home</NavLink>
            {navItems.map((item, index) => (
              <div
                key={item.id}
                className={styles.navItem}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink className={styles.navLink}>
                  {item.title} <ChevronDown />
                </NavLink>
                <div className={`${styles.navDropdown} ${activeDropdown === index ? styles.navDropdownVisible : ''}`}>
                  {item.dropdown.map((subItem) => (
                    <div
                      key={subItem.id}
                      className={styles.navDropdownItem}
                      onClick={() => handleDropdownClick(item.id, subItem.id)}
                    >
                      {subItem.name}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <NavLink to="/gallery" className={styles.navItem}>Gallery</NavLink>
            <NavLink to="/contact-us" className={styles.navItem}>Contact Us</NavLink>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
     
         
    </div>
  );
};

export default Header;
