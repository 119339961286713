// PagesManagement.jsx
import React, { useState } from 'react';
import { Pencil, Trash2, User, Plus } from 'lucide-react';
import styles from './PagesManagement.module.scss';
import Sidebar from '../../../Components/Sidebar/Sidebar';

const PagesManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Sample data for the pages table
  const pagesData = [
    { id: 38, title: 'Fee Statement', link: 'page.php?id=38', created: 'August 14, 2021', status: 'Enable', slider: 'Enable', sidebar: 'Enable', serialNo: 1 },
    { id: 37, title: 'Maintenance Expenditure', link: 'page.php?id=37', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 2 },
    { id: 36, title: 'Electrical Consumption', link: 'page.php?id=36', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 3 },
    { id: 35, title: 'Raw Material Consumption', link: 'page.php?id=35', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 4 },
    { id: 34, title: 'Grievance redressal mechanism', link: 'page.php?id=34', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 5 },
    { id: 33, title: 'DGET order', link: 'page.php?id=33', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Enable', serialNo: 6 },
    { id: 32, title: 'Funds Details', link: 'page.php?id=32', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 7 },
    { id: 31, title: 'Right to Information', link: 'page.php?id=31', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 8 },
    { id: 30, title: 'Extra Curricular Activities', link: 'page.php?id=30', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 9 },
    { id: 29, title: 'Industry Visit', link: 'page.php?id=29', created: 'October 06, 2019', status: 'Enable', slider: 'Enable', sidebar: 'Disable', serialNo: 10 },
  ];

  // Total number of pages for pagination
  const totalEntries = 36;
  const totalPages = Math.ceil(totalEntries / recordsPerPage);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle records per page change
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing records per page
  };

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Filter pages based on search term
  const filteredPages = pagesData.filter(page => 
    page.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    page.link.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get status button class based on status value
  const getStatusClass = (status) => {
    return status === 'Enable' ? styles.enableButton : styles.disableButton;
  };

  return (
    <div className={styles.main}>
    <Sidebar data={"pagemanagement"}/>
    <div className={styles.pagesManagement}>
      <div className={styles.header}>
        <div className={styles.title}>
          <User size={20} />
          <h1>All Pages</h1>
        </div>
        <button className={styles.newPageButton}>
          <Plus size={16} />
          New Page
        </button>
      </div>

      <div className={styles.controls}>
        <div className={styles.recordsPerPage}>
          <select 
            value={recordsPerPage} 
            onChange={handleRecordsPerPageChange}
            className={styles.select}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <span className={styles.recordsLabel}>records per page</span>
        </div>
        
        <div className={styles.search}>
          <label htmlFor="search">Search:</label>
          <input
            id="search"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles.searchInput}
            placeholder="Search pages..."
          />
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Title</th>
              <th>Page link</th>
              <th>Created</th>
              <th>Status</th>
              <th>Slider</th>
              <th>Sidebar</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredPages.map((page) => (
              <tr key={page.id}>
                <td>{page.serialNo}</td>
                <td>{page.title}</td>
                <td>{page.link}</td>
                <td>{page.created}</td>
                <td>
                  <button className={getStatusClass(page.status)}>
                    {page.status}
                  </button>
                </td>
                <td>
                  <button className={getStatusClass(page.slider)}>
                    {page.slider}
                  </button>
                </td>
                <td>
                  <button className={getStatusClass(page.sidebar)}>
                    {page.sidebar}
                  </button>
                </td>
                <td className={styles.actions}>
                  <button className={styles.editButton}>
                    <Pencil size={16} />
                  </button>
                  <button className={styles.deleteButton}>
                    <Trash2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.pagination}>
        <div className={styles.info}>
          Showing 1 to 10 of {totalEntries} entries
        </div>
        <div className={styles.paginationControls}>
          <button 
            className={styles.paginationButton}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            ← Previous
          </button>
          
          {[...Array(Math.min(5, totalPages))].map((_, i) => {
            const pageNumber = i + 1;
            return (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={`${styles.paginationButton} ${currentPage === pageNumber ? styles.activePage : ''}`}
              >
                {pageNumber}
              </button>
            );
          })}
          
          <button 
            className={styles.paginationButton}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next →
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PagesManagement;