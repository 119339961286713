import React from 'react';
import styles from './Results.module.scss';
import Footer from '../../../Components/Footer/Footer';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';

const Results = () => {
  const resultsData = [
    {
      trade: "ELECTRICIAN",
      session: "2017",
      admitted: 84,
      appeared: 74,
      passed: 74,
      certificated: 74
    },
    {
      trade: "FIRE TECHNOLOGY AND INDUSTRIAL SEFITY MANAGEMENT",
      session: "2017",
      admitted: 72,
      appeared: 66,
      passed: 58,
      certificated: 55
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <h1 className={styles.title}>Results</h1>
      
      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Trade</th>
                <th>Session</th>
                <th>Number of Trainees admitted</th>
                <th>Number of Trainees appeared for AITT</th>
                <th>Number of Trainees passed</th>
                <th>Number of Certificated issued</th>
              </tr>
            </thead>
            <tbody>
              {resultsData.map((row, index) => (
                <tr key={index} className={index % 2 === 0 ? styles.evenRow : ''}>
                  <td>{row.trade}</td>
                  <td>{row.session}</td>
                  <td>{row.admitted}</td>
                  <td>{row.appeared}</td>
                  <td>{row.passed}</td>
                  <td>{row.certificated}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Results;