import React from 'react';
import styles from './InstructorAwards.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const InstructorAwards = () => {
  const rows = Array.from({ length: 7 }, (_, index) => index + 1);

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Awards of Instructor</h2>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>S.No.</th>
              <th className={styles.th}>Name of the Award</th>
              <th className={styles.th}>Under Scheme</th>
              <th className={styles.th}>Won By</th>
              <th className={styles.th}>Year</th>
              <th className={styles.th}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((rowNum) => (
              <tr key={rowNum} className={styles.tr}>
                <td className={styles.td}>{rowNum}</td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
                <td className={styles.td}></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default InstructorAwards;