import React from 'react';
import styles from './FundsDetails.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const FundsDetails = () => {
  const fundsData = [
    {
      srNo: '1',
      budgetAllotted: '00',
      assistedScheme: '00',
      annualFiveYear: '00',
      budgetUsed: '00'
    }
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Funds Details</h1>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.headerCell}>Sr. NO.</th>
                <th className={styles.headerCell}>Budget allotted</th>
                <th className={styles.headerCell}>
                  Assisted Scheme
                  <span className={styles.subHeader}>(DGET/World Bank etc.)</span>
                </th>
                <th className={styles.headerCell}>
                  Annual/
                  <br />
                  Five year
                </th>
                <th className={styles.headerCell}>
                  Budget Used
                  <span className={styles.subHeader}>(link to relevant central/state directorate head)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {fundsData.map((fund) => (
                <tr key={fund.srNo} className={styles.tableRow}>
                  <td className={styles.tableCell}>{fund.srNo}</td>
                  <td className={styles.tableCell}>{fund.budgetAllotted}</td>
                  <td className={styles.tableCell}>{fund.assistedScheme}</td>
                  <td className={styles.tableCell}>{fund.annualFiveYear}</td>
                  <td className={styles.tableCell}>{fund.budgetUsed}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default FundsDetails;