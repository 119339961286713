import React, { useState } from "react";
import { Mail, Phone, MapPin } from "lucide-react";
import styles from "./Contact.module.scss";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
  };

  return (
    <div>
    <Header/>
    <div className={styles.container}>
      <h2 className={styles.heading}>Contact Us</h2>

      <div className={styles.content}>
        {/* Left - Form */}
        <div className={styles.formWrapper}>
          <h3 className={styles.subHeading}>Any Query</h3>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.inputGroup}>
              <label htmlFor="name">Name <span>*</span></label>
              <input type="text" id="name" name="name" required onChange={handleChange} />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="email">Email <span>*</span></label>
              <input type="email" id="email" name="email" required onChange={handleChange} />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="phone">Phone <span>*</span></label>
              <input type="tel" id="phone" name="phone" required onChange={handleChange} />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="message">Message <span>*</span></label>
              <textarea id="message" name="message" required onChange={handleChange}></textarea>
            </div>

            <button type="submit" className={styles.submitButton}>Send</button>
          </form>
        </div>

        {/* Right - Contact Info */}
        <div className={styles.infoWrapper}>
          <h3 className={styles.subHeading}>Contact Info</h3>
          <p>You can also reach us here:</p>
          
          <div className={styles.infoItem}>
            <MapPin size={18} />
            <span> VPO-Bagri, Laxmangarh (Sikar), 332311</span>
          </div>

          <div className={styles.infoItem}>
            <Phone size={18} />
            <span>7690032342, 8003031934</span>
          </div>

          <div className={styles.infoItem}>
            <Mail size={18} />
            <span>virendraitibagri@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactForm;
