import React from 'react';
import styles from './TechnicalStaff.module.scss';
import Header from '../../../Components/Header/Header';
import Slider from '../../../Components/Slider/Slider';
import Footer from '../../../Components/Footer/Footer';

const TechnicalStaff = () => {
  const staffData = [
    {
      id: 1,
      name: "BALVEER PRAJAPAT",
      designation: "PRINCIPAL",
      qualification: "BE",
      trade: "ELECTRONICS AND COMMUNICATION",
      joiningDate: "10 AUG.2018",
      employmentType: "REGULAR",
      ctiTrained: "NO",
      remarks: "",
      photoLink: ""
    },
    {
      id: 2,
      name: "MUKESH KUMAR",
      designation: "INSTRUCTOR",
      qualification: "B.TECH",
      trade: "ELECTRICAL",
      joiningDate: "5-Jul-17",
      employmentType: "REGULAR",
      ctiTrained: "NO",
      remarks: "",
      photoLink: ""
    }
    // Add more staff data as needed
  ];

  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.header}>
          <h2 className={styles.title}>Technical Staff</h2>
        </div>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Qualification</th>
                <th>Trade</th>
                <th>Date of Joining</th>
                <th>Regular / Adhoc / Contract</th>
                <th>CTI trained (Yes / No)</th>
                <th>Remarks</th>
                <th>Photo link</th>
              </tr>
            </thead>
            <tbody>
              {staffData.map((staff) => (
                <tr key={staff.id}>
                  <td>{staff.id}</td>
                  <td className={styles.nameCell}>{staff.name}</td>
                  <td className={styles.designationCell}>{staff.designation}</td>
                  <td>{staff.qualification}</td>
                  <td className={styles.tradeCell}>{staff.trade}</td>
                  <td>{staff.joiningDate}</td>
                  <td className={styles.employmentType}>{staff.employmentType}</td>
                  <td className={styles.ctiStatus}>{staff.ctiTrained}</td>
                  <td>{staff.remarks}</td>
                  <td>
                    {staff.photoLink && (
                      <button className={styles.photoLink}>View Photo</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default TechnicalStaff;