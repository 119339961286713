import React from 'react';
import styles from './AttendenceRecord.module.scss';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import Slider from '../../../Components/Slider/Slider';

const AttendenceRecords = () => {
  return (
    <div>
    <Header/>
    <Slider/>
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        {/* Documents Section */}
        <div className={styles.documentsSection}>
          <h2 className={styles.title}>Important Documents</h2>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td><a href="#">Society Members List</a></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td><a href="#">STUDENTS ATTENDANCE</a></td>
                </tr>
                <tr>
                  <td>3</td>
                  <td><a href="#">MOU -Qem Processing</a></td>
                </tr>
                <tr>
                  <td>4</td>
                  <td><a href="#">MOU -Alaska</a></td>
                </tr>
                <tr>
                  <td>5</td>
                  <td><a href="#">Building Map</a></td>
                </tr>
                <tr>
                  <td>6</td>
                  <td><a href="#">Electricity Bill</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Right Side Content */}
        <div className={styles.rightContent}>
          <div className={styles.feeStatement}>
            <h3>Fee Statement</h3>
            <img src="/api/placeholder/400/320" alt="Fee Statement" />
          </div>

          <div className={styles.announcement}>
            <h3>Announcement</h3>
            <div className={styles.announcementContent}>
              <p>**********</p>
              <p>Admission OPEN</p>
              <p>**********</p>
              <p>Welcome to Quality Pvt ITI</p>
              <p>**********</p>
            </div>
          </div>

          <div className={styles.downloads}>
            <h3>Download</h3>
            <ul>
              <li><a href="#">Society Members List</a></li>
              <li><a href="#">STUDENTS ATTENDANCE</a></li>
              <li><a href="#">MOU -Qem Processing</a></li>
              <li><a href="#">MOU -Alaska</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default AttendenceRecords;